import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {DtProfileShort} from '@ui/models/dt-profile.model';

@Component({
  selector: 'app-profile-alias',
  templateUrl: './profile-alias.component.html',
  styleUrls: ['./profile-alias.component.scss']
})
export class ProfileAliasComponent implements OnInit {
  @Input() profile: DtProfileShort;
  @Input() description: string;
  @Input() onlyTitle = false;
  @Input() hideAvatar = false;
  @Input() redirectable = true;

  @Output() redirectToProfile = new EventEmitter();

  constructor(private router: Router) {}

  ngOnInit(): void {}

  emitRedirectToProfile(): void {
    if (this.redirectable && this.profile) {
      this.router.navigateByUrl(`/profile/${this.profile.username}`);
      this.redirectToProfile.emit();
    }
  }
}
