import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private cacheState: {
    [methodKey: string]: {
      [paramsKey: string]: {
        value: unknown;
        expireTime: number;
      };
    };
  } = {};

  constructor() {}

  getCacheValue<ValueType>(methodKey: string, paramsKey: string, value: ValueType, ttl: number): ValueType {
    const valueInfo = this.cacheState[methodKey] ? this.cacheState[methodKey][paramsKey] : null;
    if (valueInfo && valueInfo.expireTime > new Date().getTime()) {
      return valueInfo.value as ValueType;
    } else {
      this.setCacheValue(methodKey, paramsKey, value, ttl);
      return value;
    }
  }

  private setCacheValue(methodKey: string, paramsKey: string, value: unknown, ttl: number): any {
    if (!this.cacheState[methodKey]) {
      this.cacheState[methodKey] = {};
    }
    this.cacheState[methodKey][paramsKey] = {value, expireTime: new Date().getTime() + ttl};
  }
}
