import {DtMedia} from '@ui/models/dt-media-content.model';

export interface DtProfileShort<DtAvatarType = string | DtMedia> {
  userId?: string;
  profileId: string;
  profileType?: DtProfileType;
  avatar: DtAvatarType;
  avatars?: DtMedia[];
  invisible?: string;
  username: string;
  statusHeading?: string;
  email?: string;
  completion?: number;
  hasPremiumSubscription?: boolean;
  isVerified?: boolean;
  isOnline?: boolean;
  isFan?: boolean;
  isBlocked?: boolean;
  subscriptions?: {
    isFanToViewer: boolean;
    isViewerFan: boolean;
    targetUserHasPremium: boolean;
    viewerUserHasPremium: boolean;
  };
}

export enum DtProfileType {
  DADDY = 'daddy',
  BABY = 'baby'
}
