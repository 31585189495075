import {DtPaginationResponse} from '@ui/models/dt-pagination.model';
import {DtProfileShort, DtProfileType} from '@ui/models/dt-profile.model';

export enum CustomChatEvents {
  MESSAGE_SENT = 'MESSAGE_SENT',
  MESSAGE_RECEIVED = 'MESSAGE_RECEIVED',
  MESSAGE_READ = 'MESSAGE_READ',
  MESSAGE_REACTION = 'MESSAGE_REACTION',
  MESSAGE_DELETED = 'MESSAGE_DELETED',
  CHANNEL_DELETED = 'CHANNEL_DELETED',
  TYPING = 'TYPING'
}

export interface CustomChatEventData {
  channelId: string;
}

export interface MessageSentEventData extends CustomChatEventData {
  data: Message;
}

export interface MessageReceiveEventData extends CustomChatEventData {
  data: Message;
  reciverMuted: boolean;
}

export interface MessageReadEventData extends CustomChatEventData {
  data: Message;
}

export interface MessageReactionEventData extends CustomChatEventData {
  data: Message;
}

export interface MessageDeletedEventData extends CustomChatEventData {
  data: Message;
}

export interface ChannelDeletedEventData extends CustomChatEventData {}

export interface TypingEventData extends CustomChatEventData {}

export interface UnreadMessagesInfo {
  total: number;
  channels: {[key: string]: number};
}

export interface ConversationsResponse extends DtPaginationResponse<Conversation> {
  coversations: Conversation[];
}

export interface ConversationsSearchResponse {
  profiles: ConversationSearchProfile[];
  conversations: Partial<Conversation>[];
  messages: Message[];
}

export interface ConversationSearchProfile {
  senderProfileId: string;
  profile: DtProfileShort;
}

export interface ConversationResponse {
  conversation: Conversation;
  permissions: ConversationPermissions;
}

export interface Conversation {
  _id: string;
  channelId: string;
  unionChannelId: string;
  profileId: string;
  receiverId?: string;
  receiver?: DtProfileShort;
  pushNotifications: unknown;
  translationEnabled: boolean;
  isSender: boolean;
  pinnedAt: string;
  isMuted: boolean;
  createdAt: string;
  updatedAt: string;
  latestMessage: Message;
  isNewConversation: boolean;
  permissions: ConversationPermissions;
  restrictedToWrite: boolean;
  restrictedToView: boolean;
}

export interface ConversationPermissions {
  initiator: {
    write: boolean;
    view: boolean;
  };
  recipient: {
    write: boolean;
    view: boolean;
  };
  initiatorProfileType: DtProfileType;
  recipientProfileType: DtProfileType;
  reason: string;
}

export interface MessagesResponse extends DtPaginationResponse<Message> {
  messages: Message[];
  permissions: ConversationPermissions;
}

export interface Message {
  message_id: string;
  localId?: string;
  sender_user_id: string;
  sender_profile_id: string;
  sender_username: string;
  receiver_profile_id: string;
  channel_id: string;
  message: string;
  message_translated: string;
  reaction: unknown;
  reactionAnimated: boolean;
  type: string;
  parent_id: string;
  parentMessage?: Message;
  actual_sent_at: string;
  actual_received_at: string;
  created_at: string;
  created_at_short?: string;
  read_at: string;
  restricted: boolean;
}
