import {MediaMatcher} from '@angular/cdk/layout';
import {Injectable} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {BehaviorSubject, debounceTime, fromEvent, map, Observable, startWith} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {
  private tabletMediaQuery: MediaQueryList;
  private isTabletMode = false;
  private screenMode$ = new BehaviorSubject(this.isTabletMode);

  canRestoreScroll: boolean;
  private restorablePages: {[key: string]: boolean} = {};
  private scrollHistory: {[page: string]: {x: number; y: number}} = {};

  constructor(
    private media: MediaMatcher,
    private router: Router
  ) {}

  init(): void {
    this.handleTabletModeUpdates();
    this.handleScrollPosition();
    this.handleDoubleClick();
  }

  private handleTabletModeUpdates(): void {
    this.tabletMediaQuery = this.media.matchMedia('(max-width: 1023px)');
    this.isTabletMode = this.tabletMediaQuery.matches;
    this.screenMode$.next(this.isTabletMode);
    this.tabletMediaQuery.addListener((mediaQuery) => {
      this.isTabletMode = mediaQuery.matches;
      this.screenMode$.next(this.isTabletMode);
    });
  }

  getScreenMode(): BehaviorSubject<boolean> {
    return this.screenMode$;
  }

  getWindowHeight(): Observable<number> {
    return fromEvent(window, 'resize').pipe(
      debounceTime(100),
      map(() => {
        return window.innerHeight;
      }),
      startWith(window.innerHeight)
    );
  }

  private handleScrollPosition(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.canRestoreScroll = event.navigationTrigger === 'popstate';
        if (this.restorablePages[this.router.url]) {
          this.scrollHistory[this.router.url] = {x: window.scrollX, y: window.scrollY};
        }
      }
      if (event instanceof NavigationEnd) {
        if (!this.canRestoreScroll || !this.restorablePages[this.router.url]) {
          window.scroll({top: 0, left: 0});
        }
      }
    });
  }

  enableScrollRestoration(): void {
    this.restorablePages[this.router.url] = true;
  }

  restoreScroll(): void {
    if (this.canRestoreScroll && this.restorablePages[this.router.url]) {
      const scrollPosition = this.scrollHistory[this.router.url];
      if (scrollPosition) {
        window.scroll({top: scrollPosition.y, left: scrollPosition.x});
        delete this.scrollHistory[this.router.url];
      } else {
        window.scroll({top: 0, left: 0});
      }
    }
  }

  setScrollTop(): void {
    window.scroll({
      top: 0,
      left: 0
    });
  }

  scrollToElement(element: Element): void {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }

  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  private handleDoubleClick(): void {
    document.addEventListener('dblclick', () => {});
  }

  // private handleOverScroll(): void {
  //   let isTouching = false;
  //   window.addEventListener('touchstart', () => {
  //     isTouching = true;
  //   });
  //   window.addEventListener('touchend', () => {
  //     isTouching = false;
  //     this.checkOverScroll();
  //   });
  //   window.addEventListener('scroll', () => {
  //     if (!isTouching) {
  //       this.checkOverScroll();
  //     }
  //   });
  // }
  //
  // private checkOverScroll() {
  //   const scrollHeight = document.documentElement.scrollHeight;
  //   const currentScrollPosition = window.scrollY + document.documentElement.clientHeight;
  //   const overScroll = scrollHeight - currentScrollPosition;
  //   const safariSpaceSize = 255;
  //
  //   if (overScroll < safariSpaceSize + -1) {
  //     window.scrollTo({
  //       top: document.documentElement.scrollHeight - document.documentElement.clientHeight + safariSpaceSize
  //     });
  //   }
  // }
}
